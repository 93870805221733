import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      '& .MuiBackdrop-root': {
        background: ' rgba(0, 0, 0, 0.35)',
      },
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: 25,
      padding: '30px 30px',

      minWidth: 500,
      maxWidth: 503,
      minHeight: 400,

      background: '#fff',
      top: '20vh',
      left: 'calc(50% - 200px)',
      position: 'absolute',
      borderRadius: 5,
      boxShadow: ' 0px 0px 8px 0px rgba(0, 0, 0, 0.15)',
    },
    modal_title: {
      display: '-webkit-box',
      '-webkit-line-clamp': 10,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: 18,
      marginBottom: 5,
    },
    modal_warning: {
      color: 'orange',
      marginTop: '15px',
      '& span': {
        fontWeight: 700,
        textTransform: 'uppercase',
      },
    },
    wrapper_header: {
      '& span': {
        display: 'inline-block',
        fontWeight: 700,
        fontSize: 18,
        marginRight: 5,
      },
      display: 'flex',
      alignItems: 'flex-start',
      gap: 10,
      padding: '0 35px 0 5px',
      fontWeight: 400,
      fontSize: 14,
    },
    close_button: {
      background: 'transparent',
      outline: 'none',
      border: 0,
      cursor: 'pointer',
      position: 'absolute !important',
      right: 20,
      width: 20,
      height: 20,
      zIndex: 10,
      padding: 0,

      '&:hover': {
        opacity: 0.7,
      },
    },
    wrapper_body: {
      fontSize: 14,
    },
    body_table: {
      height: '300px',
      overflowY: 'auto',
      position: 'relative',

      '&::-webkit-scrollbar': {
        width: 3,
      },

      '&::-webkit-scrollbar-thumb': {
        background: '#1A0063',
      },

      '&::-webkit-scrollbar-track': {
        width: 3,
      },

      '&::-webkit-scrollbar-thumb:hover': {
        width: 10,
      },
    },
    body_table_header: {
      fontWeight: 800,

      '&  > div': {
        paddingTop: 0,
        paddingRight: 4,
        borderBottom: '1px solid rgba(196, 196, 196, 1)',
      },
    },
    wrapper_footer: {
      marginTop: 'auto',
      display: 'flex',
      justifyContent: 'space-between',
    },

    row: {
      padding: '13px 5px',
      borderBottom: '1px solid rgba(196, 196, 196, 0.50)',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& >*:nth-child(1)': {
        width: '50%',
      },
      '& >*:nth-child(2)': {
        width: '10%',
        display: 'flex',
        justifyContent: 'center',
      },
      '& >*:nth-child(3)': {
        width: '10%',
        display: 'flex',
        justifyContent: 'center',
      },
      '& >*:nth-child(4)': {
        width: '13%',
        display: 'flex',
        justifyContent: 'center',
      },
    },
    empty_msg: {
      width: '100%',
      textAlign: 'center',
      margin: '50px 0 0',
    },
    save_btn: {
      fontSize: '16 !important',
      fontWeight: '700 !important',
      textTransform: 'unset !important',
      background: '#1A0063 !important ',
      padding: '5px 10px',
      '&:disabled': {
        opacity: 0.5,
      },
    },
    create: {
      display: 'flex',
      gap: 6,
      textTransform: 'unset !important',
      fontSize: 10,
      fontWeight: 700,
      padding: '5px 10px',
    },
    new_keyword: {
      display: 'flex',
      alignItems: 'center',
      gap: 3,
      '& input': {
        padding: 5,
      },
    },
  })
)
export default useStyles
