/* eslint-disable no-param-reassign */
import { projectConfig } from '../config'
import { getToken } from '../utils/auth'
import { removeAuth } from '../utils/logout'

let isLogout = false

const LOGOUT_REQUEST_META = '/users/logout?sessionID'

export default function setupAxios(axios) {
  axios.defaults.baseURL = projectConfig.apiUrl
  axios.defaults.timeout = 250000

  axios.interceptors.request.use(
    (config) => {
      if (getToken()) {
        config.headers = {
          ...config.headers,
          Authentication: `Bearer ${getToken()}`,
        }
      }

      const isLogoutReq = config.url.includes(LOGOUT_REQUEST_META)

      if (isLogoutReq) {
        isLogout = true
      } else if (isLogout) {
        return null
      }

      return config
    },
    axios.interceptors.response.use(
      (response) => {
        const isLogoutReq = response.config.url.includes(LOGOUT_REQUEST_META)

        if (isLogoutReq && isLogout) {
          isLogout = false
        }

        return response
      },
      async (error) => {
        const { response } = error
        if (response?.status === 401) {
          removeAuth('AUTH_NSL')
          removeAuth('USER_ID')
          removeAuth('TOKEN_NSL')
          window.location = '/sign-in'
        }
        return Promise.reject(response)
      }
    ),
    (err) => Promise.reject(err)
  )
}
