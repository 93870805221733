/* eslint-disable import/no-cycle */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getAccountInfo, getUserInfo, userAuth, userLogoutReq } from '../../api/user'

import { getAccountID, getToken, getUserId } from '../../utils/auth'
import { getChannels } from '../../api/channels'
import { removeAuth } from '../../utils/logout'

const initialState = {
  data: {
    accountID: '',
    accID: '',
    customerName: '',
    userID: '',
    channels: [],
    isChannelsLoading: false,
    isUserDataLoading: false,
    channelsError: null,
    accountInfo: null,
  },
  isLoading: false,
}

export const getUser = createAsyncThunk('user/get', async (data, { rejectWithValue }) => {
  try {
    const userId = data?.userID || getUserId()
    const resp = await getUserInfo({ userId, accountId: data.accountID })
    return {
      ...resp.data,
      accountID: getAccountID(),
    }
  } catch (error) {
    return rejectWithValue(error.toString())
  }
})
export const getAccountInfoAction = createAsyncThunk(
  'user/getAccountInfoAction',
  async (accountId, { rejectWithValue }) => {
    try {
      const resp = await getAccountInfo({ accountId })
      return {
        ...resp.data,
      }
    } catch (error) {
      return rejectWithValue(error.toString())
    }
  }
)

export const userLogin = createAsyncThunk('user/sign-in', async (data, { rejectWithValue }) => {
  try {
    const user = await userAuth({
      ...data,
    })

    return user
  } catch (error) {
    return rejectWithValue(error)
  }
})
export const userLogout = createAsyncThunk('user/logout', async (data, { rejectWithValue }) => {
  try {
    const user = await userLogoutReq({
      sessionID: getToken(),
    })

    return user
  } catch (error) {
    return rejectWithValue(error?.response)
  }
})

export const getChannelsAction = createAsyncThunk('user/getChannelsAction', async (accountId, { rejectWithValue }) => {
  try {
    const channels = await getChannels(accountId)
    return channels
  } catch (error) {
    return rejectWithValue(error?.response)
  }
})
/* eslint-disable no-param-reassign */
export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUser.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.isLoading = false
        if (!state.data) state.user = initialState.data
        state.data = { ...action.payload }
      })
      .addCase(getUser.rejected, (state) => {
        state.isLoading = false
      })
    builder
      .addCase(userLogout.pending, (state) => {
        state.isLoading = true
      })
      .addCase(userLogout.fulfilled, (state) => {
        state.data = initialState.data
        removeAuth('AUTH_NSL')
        removeAuth('USER_ID')
        removeAuth('TOKEN_NSL')
        window.location = '/sign-in'
      })
      .addCase(userLogout.rejected, (state) => {
        state.isLoading = false
      })
    builder
      .addCase(userLogin.pending, (state) => {
        state.isLoading = true
      })
      .addCase(userLogin.fulfilled, (state, action) => {
        state.isLoading = false
        state.data = {
          ...state.data,
          ...action?.payload?.data,
        }
      })
      .addCase(userLogin.rejected, (state) => {
        state.isLoading = false
      })

    builder
      .addCase(getChannelsAction.pending, (state) => {
        state.isChannelsLoading = true
      })
      .addCase(getChannelsAction.fulfilled, (state, action) => {
        state.isChannelsLoading = false
        state.channels = action.payload.data
      })
      .addCase(getChannelsAction.rejected, (state) => {
        state.isChannelsLoading = false
      })

    builder
      .addCase(getAccountInfoAction.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getAccountInfoAction.fulfilled, (state, action) => {
        state.isLoading = false
        state.accountInfo = action.payload
      })
      .addCase(getAccountInfoAction.rejected, (state) => {
        state.isLoading = false
      })
  },
})
/* eslint-enable no-param-reassign */

export const userLoading = (state) => state.user.isLoading
export const userData = (state) => state.user.data
export const accountInfo = (state) => state.user.accountInfo
export default userSlice.reducer
