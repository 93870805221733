import { useEffect, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
  Box,
  Button,
  Card,
  CardActions,
  FormControlLabel,
  FormGroup,
  ThemeProvider,
  TextField,
  Alert,
} from '@mui/material'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import ThemeNSLLight from '../../../theme/theme-nsl-light'
import { setAuth } from '../../../utils/auth'
import { errorParserSingle } from '../../../utils/errorParser'
import { getAccountInfoAction, getChannelsAction, getUser, userLogin, userLogout } from '../../../store/slice/user'
import Loader from '../../../components/Loader'

import IOSSwitch from '../../../components/Form/IOSSwitch'

import useStyles from './styles'
import { COCKPIT, SETTINGS } from '../../../routes/constants'

const SignInFormSchema = () =>
  Yup.object().shape({
    email: Yup.string().email('The email address value is not valid.').required('Required'),
    password: Yup.string().required('Required'),
  })

const LoginForm = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const classes = useStyles()
  const [fetching, setFetching] = useState(false)
  const initialValues = {
    email: searchParams.get('email') ? searchParams.get('email') : '',
    password: '',
  }
  const [remember, setIsRemember] = useState(false)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)

  const formik = useFormik({
    initialValues: { ...initialValues },
    validationSchema: SignInFormSchema(),
    onSubmit: async (values) => {
      setIsButtonDisabled(true)
      setFetching(true)
      try {
        const auth = await dispatch(userLogin({ ...values })).unwrap()
        if (auth) {
          dispatch(getUser({ userID: auth.data.userID, accountID: auth.data.accountID }))
        }
        setAuth('AUTH_NSL', auth?.data?.accountID, remember)
        setAuth('USER_ID', auth?.data?.userID, remember)
        setAuth('TOKEN_NSL', auth?.data?.sessionToken, remember)
        dispatch(getAccountInfoAction(auth?.data?.accountID))
        dispatch(getChannelsAction(auth?.data?.accountID)).then((res) => {
          if (res.payload.status === 200) {
            if (res.payload?.data.length) {
              navigate(COCKPIT?.link)
            } else navigate(SETTINGS?.link)
          } else {
            dispatch(userLogout())
          }
        })
        formik.resetForm()
      } catch (error) {
        errorParserSingle(error, formik)
      }
      setIsButtonDisabled(false)
      setFetching(true)
    },
  })

  useEffect(() => {
    const { email, password } = formik.values
    if (email.trim() && password.trim()) {
      if (formik.errors.password || formik.errors.email) {
        setIsButtonDisabled(true)
      } else setIsButtonDisabled(false)
    } else {
      setIsButtonDisabled(true)
    }
  }, [formik.errors, formik.values])

  const handleKeyPress = (event) => {
    const { which, keyCode } = event
    if (keyCode === 13 || which === 13) {
      if (!isButtonDisabled) {
        formik.handleSubmit()
      }
    }
  }

  const handleInputChange = (name, value) => {
    formik.setFieldValue(name, value)
  }

  const handleRememberChange = (event) => {
    setIsRemember(event.target.checked)
  }
  return (
    <ThemeProvider theme={ThemeNSLLight}>
      <Box className={classes.card}>
        <Card className={classes.card_wrap}>
          <form className={classes.form} noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
            <Box className={classes.header}>
              <div className={classes.header_title}>Welcome</div>
              <div className={classes.header_subtitle}>Enter your email and password to sign in.</div>
            </Box>
            {formik.status && (
              <Alert className={classes.form_alert} severity="error">
                {formik.status?.message}
              </Alert>
            )}
            <FormGroup className={classes.form_group}>
              <TextField
                error={!!formik.errors?.email && !!formik.touched?.email}
                fullWidth
                id="username"
                type="email"
                label="Your email address"
                placeholder="Your email address"
                value={formik.values?.email}
                helperText={formik.touched?.email && formik.errors?.email ? formik.errors?.email : ''}
                onChange={(e) => handleInputChange('email', e.target.value)}
                onFocus={() => formik.setFieldTouched('email', false)}
                onBlur={() => formik.setFieldTouched('email', true)}
                onKeyPress={handleKeyPress}
              />
              <TextField
                error={!!formik.errors?.password && !!formik.touched?.password}
                fullWidth
                id="password"
                type="password"
                label="Your password"
                placeholder="Your password"
                value={formik.values?.password}
                helperText={formik.touched?.password && formik.errors?.password ? formik.errors?.password : ''}
                onChange={(e) => handleInputChange('password', e.target.value)}
                onFocus={() => formik.setFieldTouched('password', false)}
                onBlur={() => formik.setFieldTouched('password', true)}
                onKeyPress={handleKeyPress}
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                className={classes.labelCheckbox}
                control={<IOSSwitch checked={remember} onChange={handleRememberChange} />}
                label="Remember me"
              />
            </FormGroup>
            <CardActions className={classes.form_footer}>
              <Box className={classes.form_footer_box}>
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  className={classes.form_btn}
                  disabled={isButtonDisabled}
                  type="submit"
                >
                  {fetching ? <Loader className="loader" /> : 'Sign In'}
                </Button>
              </Box>
              <Box className={classes.form_footer_box}>
                <Box className={classes.form_footer_text}>
                  Don&apos;t have an account? <Link to="/sign-up">Sign up</Link>
                </Box>
              </Box>
            </CardActions>
          </form>
        </Card>
      </Box>
    </ThemeProvider>
  )
}
export default LoginForm
