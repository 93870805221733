/* eslint-disable import/no-cycle */
import Cookies from 'universal-cookie'

import { disposeCockpit } from '../store/slice/cockpit'
import { disposeProduct } from '../store/slice/product'
import { userLogout } from '../store/slice/user'

const cookies = new Cookies()

export function removeAuth(prop) {
  cookies.remove(prop)
  sessionStorage.removeItem(prop)
}

export function logout(dispatch) {
  dispatch(userLogout())
  dispatch(disposeCockpit())
  dispatch(disposeProduct())
}
