/* eslint-disable consistent-return */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import {
  productAnalysisCSVReq,
  productAnalysisGroupingsReq,
  productAnalysisReq,
  productStatusUpdateReq,
  uploadActivationFileReq,
  uploadAttributesFileReq,
} from '../../api/product'
import { errorParserRequest } from '../../utils/errorParser'
import { TOAST_DEFAULT_CONFIG } from '../../constants/toast'
// import { groupings } from '../../pages/Cockpit/fakeAPI/groupings'

const initialState = {
  isActivationUploading: false,
  isAttributesUploading: false,
  productGroupings: null,
  productGroupingsOptions: null,
  productGroupingsOptionsLoaded: false,
  isProductGroupingLoading: false,
  productAnalysisData: null,
  isproductAnalysisDataLoading: false,
  isStatusUpdateLoading: false,
}

export const NO_GROUP_FILTER_OPTION = {
  value: 'filter-no-group',
  label: 'NONE',
}

const mutationGrouping = (data) => {
  if (!data) {
    return []
  }

  const empty = data?.productAttributes?.find((item) => !item.internalID && !item.label)
  const mapped = data?.productAttributes?.map((item) => {
    if (!item.internalID && !item.label) {
      return NO_GROUP_FILTER_OPTION
    }
    return {
      value: item.internalID,
      label: item.label,
    }
  })

  if (!empty) {
    mapped.unshift(NO_GROUP_FILTER_OPTION)
  }

  return mapped
}

const mutationToOptions = (res) => ({
  channels:
    res?.channels?.map((item) => ({
      value: item.id,
      label: item.channelName,
    })) || [],
  groupBy: mutationGrouping(res),
})

export const uploadActivationFile = createAsyncThunk(
  'product/uploadActivationFile',
  async (data, { rejectWithValue }) => {
    try {
      const response = await uploadActivationFileReq(data.id, data.file, data.requestConfig)
      if (response.status === 200 || response.status === 201) {
        return response.data
      }
    } catch (error) {
      return rejectWithValue(errorParserRequest(error))
    }
  }
)

export const uploadAttributesFile = createAsyncThunk(
  'product/uploadAttributesFile',
  async (data, { rejectWithValue }) => {
    try {
      const response = await uploadAttributesFileReq(data.id, data.file, data.requestConfig)
      if (response.status === 200 || response.status === 201) {
        return response.data
      }
    } catch (error) {
      return rejectWithValue(errorParserRequest(error))
    }
  }
)

export const productAnalysisGroupings = createAsyncThunk(
  'product/productAnalysisGroupings',
  async (data, { rejectWithValue }) => {
    try {
      const response = await productAnalysisGroupingsReq(data)
      if (response.status === 200 || response.status === 201) {
        return response.data
      }
    } catch (error) {
      const err = errorParserRequest(error)
      toast.error(err, TOAST_DEFAULT_CONFIG)
      return rejectWithValue()
    }
  }
)

export const productAnalysis = createAsyncThunk('product/productAnalysis', async (data, { rejectWithValue }) => {
  try {
    const response = await productAnalysisReq(data.id, data.params)
    if (response.status === 200 || response.status === 201) {
      return response.data
    }
  } catch (error) {
    return rejectWithValue(errorParserRequest(error))
  }
})

export const productAnalysisCSV = createAsyncThunk('product/productAnalysisCSV', async (data, { rejectWithValue }) => {
  try {
    const response = await productAnalysisCSVReq(data.id, data.params)
    if (response.status === 200 || response.status === 201) {
      return response.data
    }
  } catch (error) {
    return rejectWithValue(errorParserRequest(error))
  }
})

export const productUpdateStatus = createAsyncThunk(
  'product/productUpdateStatus',
  async (data, { rejectWithValue }) => {
    try {
      const response = await productStatusUpdateReq(data.id, data.data)
      if (response.status === 200 || response.status === 201) {
        return response.data
      }
    } catch (error) {
      return rejectWithValue(errorParserRequest(error))
    }
  }
)

/* eslint-disable no-param-reassign */
export const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    disposeProduct: (state) => {
      state.isActivationUploading = false
      state.isAttributesUploading = false
      state.productGroupings = null
      state.productGroupingsOptions = null
      state.productGroupingsOptionsLoaded = false
      state.isProductGroupingLoading = false
      state.productAnalysisData = null
      state.isproductAnalysisDataLoading = false
      state.isStatusUpdateLoading = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadActivationFile.pending, (state) => {
        state.isActivationUploading = true
      })
      .addCase(uploadActivationFile.fulfilled, (state) => {
        state.isActivationUploading = false
      })
      .addCase(uploadActivationFile.rejected, (state) => {
        state.isActivationUploading = false
      })
      .addCase(uploadAttributesFile.pending, (state) => {
        state.isAttributesUploading = true
      })
      .addCase(uploadAttributesFile.fulfilled, (state) => {
        state.isAttributesUploading = false
      })
      .addCase(uploadAttributesFile.rejected, (state) => {
        state.isAttributesUploading = false
      })
      .addCase(productAnalysisGroupings.pending, (state) => {
        state.isProductGroupingLoading = true
      })
      .addCase(productAnalysisGroupings.fulfilled, (state, action) => {
        state.isProductGroupingLoading = false
        state.productGroupings = action.payload
        state.productGroupingsOptions = mutationToOptions(action.payload)
        state.productGroupingsOptionsLoaded = true
      })
      .addCase(productAnalysisGroupings.rejected, (state) => {
        state.isProductGroupingLoading = false
        // TODO: DELETE AFTER BACK-END FIX
        // state.productGroupingsOptions = mutationToOptions(groupings)
      })
      .addCase(productAnalysis.pending, (state) => {
        state.isproductAnalysisDataLoading = true
      })
      .addCase(productAnalysis.fulfilled, (state, action) => {
        state.isproductAnalysisDataLoading = false
        state.productAnalysisData = action.payload
      })
      .addCase(productAnalysis.rejected, (state) => {
        state.isproductAnalysisDataLoading = false
      })
      .addCase(productAnalysisCSV.pending, (state) => {
        state.isproductAnalysisCSVDataLoading = true
      })
      .addCase(productAnalysisCSV.fulfilled, (state) => {
        state.isproductAnalysisCSVDataLoading = false
      })
      .addCase(productAnalysisCSV.rejected, (state) => {
        state.isproductAnalysisCSVDataLoading = false
      })
      .addCase(productUpdateStatus.pending, (state) => {
        state.isStatusUpdateLoading = true
      })
      .addCase(productUpdateStatus.fulfilled, (state) => {
        state.isStatusUpdateLoading = false
      })
      .addCase(productUpdateStatus.rejected, (state) => {
        state.isStatusUpdateLoading = false
      })
  },
})
/* eslint-enable no-param-reassign */

export const selectActivationUploading = (state) => state.product.isActivationUploading
export const selectAttributesUploading = (state) => state.product.isAttributesUploading
export const selectProductGroupings = (state) => state.product.productGroupings
export const selectProductGroupingsLoading = (state) => state.product.isProductGroupingLoading
export const selectProductGroupingsOptions = (state) => state.product.productGroupingsOptions
export const selectProductGroupingsOptionsLoaded = (state) => state.product.productGroupingsOptionsLoaded
export const selectProductAnalysisData = (state) => state.product.productAnalysisData
export const selectProductAnalysisDataLoading = (state) => state.product.isproductAnalysisDataLoading
export const selectIsStatusUpdateLoading = (state) => state.product.isStatusUpdateLoading
export const selectIsproductAnalysisCSVDataLoading = (state) => state.product.isproductAnalysisCSVDataLoading

export const { disposeProduct } = productSlice.actions

export default productSlice.reducer
