import React from 'react'

import useStyles from './styles'

const Copyright = () => {
  const classes = useStyles()

  return (
    <div className={classes.copyright}>
      &#169; {new Date().getFullYear()}, Neural Search Labs GmbH. <br /> Made with ❤️ by{' '}
      <a target="_blank" href="https://emit.digital" rel="noreferrer">
        emit.digital
      </a>
    </div>
  )
}
export default Copyright
