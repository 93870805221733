/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as d3 from 'd3-fetch'
import Box from '@mui/material/Box'
import { toast } from 'react-toastify'
import { setPage } from '../../store/slice/page'

import PageWrapper from '../../containers/PageWrapper'
import PageHeadline from '../../components/PageHeadline'
import CustomButton from '../../components/CustomButton'

import { ReactComponent as DonwloadIcon } from '../../assets/icons/download.svg'
import { ReactComponent as UploadIcon } from '../../assets/icons/upload.svg'

import useStyles from './styles'
import { userData } from '../../store/slice/user'
import {
  selectActivationUploading,
  selectAttributesUploading,
  uploadActivationFile,
  uploadAttributesFile,
} from '../../store/slice/product'
import { TOAST_DEFAULT_CONFIG } from '../../constants/toast'
import { getCSVAssignmentReq, getCSVAttributesReq } from '../../api/file'
import { downloadURI } from '../../utils/download-file'

const types = {
  ACTIVATION: 'activation',
  ATTRIBUTES: 'attributes',
}

const Configuration = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const user = useSelector(userData)
  const isActivationLoading = useSelector(selectActivationUploading)
  const isAttributionLoading = useSelector(selectAttributesUploading)

  const hiddenActivationFileInput = useRef(null)
  const hiddenAttributionFileInput = useRef(null)

  const [progress, setProgress] = useState(0)

  const requestConfig = () => ({
    onUploadProgress: (progressEvent) => {
      const { total } = progressEvent
      const persent = total / 100
      const uploaded = progressEvent.loaded / persent
      setProgress(Number(uploaded.toFixed()))
    },
  })

  const handleChangeFile = async (event, type) => {
    const url = URL.createObjectURL(event.target.files[0])
    let fileUploaded

    try {
      fileUploaded = await d3.text(url)
    } catch {
      fileUploaded = ''
    }

    if (fileUploaded) {
      const payload = { id: user.accountID, file: fileUploaded, requestConfig: requestConfig() }
      if (type === types?.ACTIVATION) {
        dispatch(uploadActivationFile(payload))
          .unwrap()
          .then(() => {
            toast.success('Product activation file successfully processed', TOAST_DEFAULT_CONFIG)
            hiddenActivationFileInput.current.value = ''
            setProgress(0)
          })
          .catch((err) => {
            toast.error(
              `The uploaded CSV file could not be processed. Individual error messages: ${err}`,
              TOAST_DEFAULT_CONFIG
            )
            hiddenActivationFileInput.current.value = ''
            setProgress(0)
          })
      }
      if (type === types?.ATTRIBUTES) {
        dispatch(uploadAttributesFile(payload))
          .unwrap()
          .then(() => {
            toast.success('Product attributes file successfully processed', TOAST_DEFAULT_CONFIG)
            hiddenAttributionFileInput.current.value = ''
            setProgress(0)
          })
          .catch((err) => {
            toast.error(
              `The uploaded CSV file could not be processed. Individual error messages: ${err}`,
              TOAST_DEFAULT_CONFIG
            )
            hiddenAttributionFileInput.current.value = ''
            setProgress(0)
          })
      }
    }
  }

  const handleFileUpload = (type) => {
    if (type === types?.ACTIVATION) {
      hiddenActivationFileInput.current.click()
    }
    if (type === types?.ATTRIBUTES) {
      hiddenAttributionFileInput.current.click()
    }
  }

  const downloadFile = async (type) => {
    const req = type === types.ACTIVATION ? getCSVAssignmentReq : getCSVAttributesReq
    const res = await req({ accountID: user.accountID })

    const title =
      type === types.ACTIVATION
        ? `DeepCliq_account${user.accID}_productActivationStatus.csv`
        : `DeepCliq_account${user.accID}_productAttributes.csv`

    const blob = new Blob(
      [
        new Uint8Array([0xef, 0xbb, 0xbf]), // UTF-8 BOM
        res.data,
      ],
      { type: 'application/csv;charset=utf-8' }
    )
    const u = window.URL.createObjectURL(blob)
    downloadURI(u, title)
    window.URL.revokeObjectURL(u)
  }

  useEffect(() => {
    dispatch(setPage('configuration'))
  })

  return (
    <PageWrapper>
      <Box className={classes.page_wrapper}>
        <PageHeadline />
        <Box className={classes.page_content}>
          <Box className={classes.configuration}>
            <Box className={classes.configuration_box}>
              <Box className={classes.configuration_title}>Activate or De-Activate products</Box>
              <Box className={classes.configuration_desc}>
                Check which products are actively advertised by downloading the “Activation file”. You can change the
                activation status in the file and upload it again as CSV UTF-8. Please, don’t change the headings.
              </Box>
              <Box className={classes.configuration_btn}>
                <Box className={classes.configuration_btn_component}>
                  <CustomButton
                    icon={<DonwloadIcon />}
                    onClick={() => {
                      downloadFile(types.ACTIVATION)
                    }}
                  >
                    <span>Download Activation File</span>
                  </CustomButton>
                </Box>
                <Box className={classes.configuration_btn_component}>
                  <CustomButton
                    icon={<UploadIcon />}
                    onClick={() => handleFileUpload(types?.ACTIVATION)}
                    loadingText="Uploading"
                    isLoading={isActivationLoading}
                    withProgress={progress < 100}
                    progress={progress}
                  >
                    <span>Upload Activation File</span>
                  </CustomButton>
                  <input
                    className={classes.hidden}
                    type="file"
                    ref={hiddenActivationFileInput}
                    onChange={(e) => handleChangeFile(e, types?.ACTIVATION)}
                    accept=".csv"
                  />
                </Box>
              </Box>
            </Box>
            <Box className={classes.configuration_box}>
              <Box className={classes.configuration_title}>Manage product attributes</Box>
              <Box className={classes.configuration_desc}>
                Check the attributes of your products by downloading the “Attributes file”. You can change e.g. the
                brand, the categories the product is assigned to, the colour etc. When done, you can upload it again as
                CSV UTF-8. Please, don’t change the headings.
              </Box>
              <Box className={classes.configuration_btn}>
                <Box className={classes.configuration_btn_component}>
                  <CustomButton
                    icon={<DonwloadIcon />}
                    onClick={() => {
                      downloadFile(types.ATTRIBUTES)
                    }}
                  >
                    <span>Download attributes File</span>
                  </CustomButton>
                </Box>
                <Box className={classes.configuration_btn_component}>
                  <CustomButton
                    icon={<UploadIcon />}
                    onClick={() => handleFileUpload(types?.ATTRIBUTES)}
                    loadingText="Uploading"
                    isLoading={isAttributionLoading}
                    withProgress={progress < 100}
                    progress={progress}
                  >
                    <span>Upload attributes File</span>
                  </CustomButton>
                  <input
                    className={classes.hidden}
                    type="file"
                    ref={hiddenAttributionFileInput}
                    onChange={(e) => handleChangeFile(e, types?.ATTRIBUTES)}
                    accept=".csv"
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </PageWrapper>
  )
}
export default Configuration
