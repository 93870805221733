/* eslint-disable no-unused-vars */
import { Box, Button, CircularProgress, IconButton, Modal, TextField } from '@mui/material'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import { memo, useEffect, useMemo, useState } from 'react'
import ControlPointIcon from '@mui/icons-material/ControlPoint'
import ClearIcon from '@mui/icons-material/Clear'
import { toast } from 'react-toastify'
import useStyles from './style'
import IOSSwitch from '../Form/IOSSwitch'
import { changeProductKeyword, productKeyword } from '../../api/product'
import { getAccountID } from '../../utils/auth'
import { TOAST_DEFAULT_CONFIG } from '../../constants/toast'
import { errorParserRequest } from '../../utils/errorParser'

const changedKey = {
  book: 'book_productCampaign_allSuperCampaigns_sameLanguage',
  block: 'block_productCampaign_allSuperCampaigns_sameLanguage',
  ignore: 'ignore_productCampaign_allSuperCampaigns_sameLanguage',
}

const newItemDefaultInfo = {
  searchPhrase: '',
  book_productCampaign: false,
  book_productCampaign_allSuperCampaigns_sameLanguage: false,
  block_productCampaign: false,
  block_productCampaign_allSuperCampaigns_sameLanguage: false,
  ignore_productCampaign: false,
  ignore_productCampaign_allSuperCampaigns_sameLanguage: false,
  conflict: false,
  book: false,
  block: false,
  ignore: false,
  isNewKeyword: true,
}

const PLATFORM = {
  BOL: 'Bol',
}
const LIMIT = {
  Bol: 100,
}

const KeywordModal = ({ open, productData, handleClose = () => {} }) => {
  const classes = useStyles()

  const [list, setList] = useState([])
  const [newKeywordList, setNewKeywordList] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [isUpdating, setIsUpdating] = useState(false)

  const isChangesExist = useMemo(
    () =>
      list.filter(
        (item) =>
          item.book !== item[changedKey.book] ||
          item.block !== item[changedKey.block] ||
          item.ignore !== item[changedKey.ignore]
      ).length,
    [list]
  )

  const isNewKeywordExist = useMemo(() => newKeywordList.filter((item) => item.searchPhrase).length, [newKeywordList])

  const showCountWarning = useMemo(() => {
    const combined = list.concat(newKeywordList)
    const count = combined.filter((item) => item.book || item.block).length

    return count > LIMIT[PLATFORM.BOL]
  }, [list, newKeywordList])

  const onChangeExistKeywordsHandler = (value, key, index) => {
    list[index].book = false
    list[index].block = false
    list[index].ignore = false
    list[index][key] = value
    setList([...list])
  }

  const onChangeNewKeywordHandler = (value, key, index) => {
    if (key !== 'searchPhrase') {
      newKeywordList[index].book = false
      newKeywordList[index].block = false
      newKeywordList[index].ignore = false
    }
    newKeywordList[index][key] = value
    setNewKeywordList([...newKeywordList])
  }

  const getData = async () => {
    setIsLoading(true)

    try {
      const { productID, channelID } = productData
      const res = await productKeyword(getAccountID(), channelID, productID)

      const mapped = res.data?.map((item) => ({
        ...item,
        book: item[changedKey.book],
        block: item[changedKey.block],
        ignore: item[changedKey.ignore],
      }))

      setList(mapped || [])
      setIsLoading(false)
    } catch (err) {
      toast.error(errorParserRequest(err), TOAST_DEFAULT_CONFIG)
      setIsLoading(false)
    }
  }

  const saveChanges = async () => {
    setIsUpdating(true)
    try {
      const { productID, channelID } = productData
      const promises = []

      list
        .filter(
          (item) =>
            item.book !== item[changedKey.book] ||
            item.block !== item[changedKey.block] ||
            item.ignore !== item[changedKey.ignore]
        )
        .concat(newKeywordList)
        .map((item) => ({
          searchPhrase: item.searchPhrase,
          book_productCampaign: item.book_productCampaign,
          book_productCampaign_allSuperCampaigns_sameLanguage: item.book,
          block_productCampaign: item.block_productCampaign,
          block_productCampaign_allSuperCampaigns_sameLanguage: item.block,
          ignore_productCampaign: item.ignore_productCampaign,
          ignore_productCampaign_allSuperCampaigns_sameLanguage: item.ignore,
          conflict: item.conflict,
        }))
        .forEach((item) => {
          const promise = changeProductKeyword(getAccountID(), channelID, productID, item)
            .then((res) => {
              if (res.status === 200 || res.status === 201) {
                toast.success(`${res?.data?.Response} `, TOAST_DEFAULT_CONFIG)
              }
            })
            .catch((err) => {
              const errors = err.response.data?.errorMessages?.reduce((acc, curr) => [...acc, curr?.errorMessage], [])
              toast.error(`${errors.join(', ')}`, TOAST_DEFAULT_CONFIG)
            })
          promises.push(promise)
        })

      Promise.all(promises)
        .then(() => {
          getData()
        })
        .finally(() => {
          setNewKeywordList([])
          setIsUpdating(false)
        })
    } catch (err) {
      toast.error(errorParserRequest(err), TOAST_DEFAULT_CONFIG)
      setIsUpdating(false)
    }
  }

  const getRow = (item, index, onChangeHandler) => (
    <Box className={classes.row}>
      {item.isNewKeyword ? (
        <div className={classes.new_keyword}>
          <TextField
            fullWidth
            type="text"
            placeholder="Enter keyword"
            value={item.searchPhrase}
            onChange={(e) => onChangeHandler(e.target.value, 'searchPhrase', index)}
          />
          <ClearIcon
            sx={{
              width: 20,
              height: 20,
              cursor: 'pointer',
            }}
            onClick={() => setNewKeywordList((prev) => prev.filter((val, i) => i !== index))}
          />
        </div>
      ) : (
        <span title={item.searchPhrase}>{item.searchPhrase}</span>
      )}
      <span>
        <IOSSwitch
          checked={item.book}
          onChange={(e) => {
            onChangeHandler(e.target.checked, 'book', index)
          }}
        />
      </span>
      <span>
        <IOSSwitch
          checked={item.block}
          onChange={(e) => {
            onChangeHandler(e.target.checked, 'block', index)
          }}
        />
      </span>
      <span>
        <IOSSwitch
          checked={item.ignore}
          onChange={(e) => {
            onChangeHandler(e.target.checked, 'ignore', index)
          }}
        />
      </span>
    </Box>
  )

  useEffect(() => {
    if (open && productData) {
      getData()
    }
    if (!open) {
      setNewKeywordList([])
    }
  }, [open, productData])

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      className={classes.modal}
    >
      <Box className={classes.wrapper}>
        <Box className={classes.wrapper_header}>
          <div>
            <div title={productData?.name} className={classes.modal_title}>
              <span>Product:</span>
              {productData?.name}
            </div>
            <div className={classes.modal_title}>
              <span>EAN:</span>
              {productData?.ean}
            </div>
            {showCountWarning && (
              <div className={classes.modal_warning}>
                <span>Warning:</span> More than {LIMIT[PLATFORM.BOL]} keywords selected. Not all keywords will be
                uploaded to&nbsp;{PLATFORM.BOL}.
              </div>
            )}
          </div>
          <button className={classes.close_button} onClick={handleClose} type="button">
            <CancelOutlinedIcon
              color="primary"
              sx={{
                width: 20,
                height: 20,
              }}
            />
          </button>
        </Box>
        <Box className={classes.wrapper_body}>
          <Box className={classes.body_table_header}>
            <Box className={classes.row}>
              <span>Keyword</span>
              <span>Book</span>
              <span>Block</span>
              <span>Ignore</span>
            </Box>
          </Box>
          <Box className={classes.body_table}>
            {!isLoading && newKeywordList.map((item, index) => getRow(item, index, onChangeNewKeywordHandler))}
            {!isLoading && list.map((item, index) => getRow(item, index, onChangeExistKeywordsHandler))}
            {(isLoading || isUpdating) && (
              <Box
                sx={{
                  position: 'absolute',
                  left: '0',
                  top: '0',
                  display: 'flex',
                  height: '100%',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <CircularProgress />
              </Box>
            )}
            {!list.length && !isLoading && <div className={classes.empty_msg}>No keywords found</div>}
          </Box>
        </Box>
        <Box className={classes.wrapper_footer}>
          <Button
            variant="outlined"
            color="secondary"
            className={classes.create}
            onClick={() => setNewKeywordList((prev) => [{ ...newItemDefaultInfo }, ...prev])}
          >
            <ControlPointIcon sx={{ width: 16, height: 16 }} /> Keyword
          </Button>
          <Button
            variant="contained"
            color="secondary"
            disabled={!isChangesExist && !isNewKeywordExist}
            className={classes.save_btn}
            onClick={saveChanges}
          >
            Save changes
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default memo(KeywordModal)
