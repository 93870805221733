import { useEffect, useState } from 'react'
import { Box, Button, Card, CardActions, FormGroup, ThemeProvider, TextField, Alert } from '@mui/material'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import ThemeNSLLight from '../../../theme/theme-nsl-light'

import useStyles from './styles'
import CheckboxRound from '../../../components/CheckboxRound'
import { userCreate } from '../../../api/user'
import { errorParserSingle } from '../../../utils/errorParser'
/* eslint-disable-next-line */
import { parsePhoneNumberFromString } from 'libphonenumber-js'

// eslint-disable-next-line no-useless-escape
const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@~#$%№^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,}$/
/* eslint-disable-next-line */
Yup.addMethod(Yup.string, 'phone', function (message) {
  /* eslint-disable-next-line */
  return this.test('phone', message, function (value) {
    const { path, createError } = this
    try {
      const number = parsePhoneNumberFromString(`+ ${value.replace('+', '')}`)
      return number.isValid()
    } catch (error) {
      return createError({ path, message })
    }
  })
})
const SignInFormSchema = () =>
  Yup.object().shape({
    companyName: Yup.string()
      .test('notEmpty', 'Required', (value) => value.trim() !== '')
      .required('Required'),
    firstName: Yup.string()
      .test('notEmpty', 'Required', (value) => value.trim() !== '')
      .required('Required'),
    lastName: Yup.string()
      .test('notEmpty', 'Required', (value) => value.trim() !== '')
      .required('Required'),
    email: Yup.string().email('The email address value is not valid.').required('Required'),
    phone: Yup.string().phone('Invalid phone number').required('Incorrect format.'),
    password: Yup.string().required('Required').matches(passwordRules, {
      message:
        'Password must contain at least 8 characters, one uppercase and one lowercase letter, number and special symbol',
    }),
    isConfirm: Yup.bool().oneOf([true], 'Field must be checked').required('Required'),
    rePassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Required'),
  })

const initialValues = {
  companyName: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  password: '',
  rePassword: '',
  isConfirm: '',
}

const SignUpForm = () => {
  const classes = useStyles()
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)
  const [isRegisterFinish, setIsRegisterFinish] = useState(false)
  const [currentEmail, setCurrentEmail] = useState('')
  const formik = useFormik({
    initialValues: { ...initialValues },
    validationSchema: SignInFormSchema(),
    onSubmit: async (values) => {
      setIsButtonDisabled(true)
      try {
        await userCreate({ ...values })
        setIsRegisterFinish(true)
        setCurrentEmail(values.email)
        formik.resetForm()
      } catch (error) {
        if (error.response.data?.errors) {
          const errorsData = error.response.data?.errors?.reduce((acc, curr) => {
            const temp = { [curr.path?.replace('/', '')]: curr.message }
            return { ...acc, ...temp }
          }, {})
          formik.setErrors(errorsData)
        } else {
          errorParserSingle({ status: error.response?.status, data: error.response.data }, formik)
        }

        setIsRegisterFinish(false)
      }
      setIsButtonDisabled(false)
    },
  })

  useEffect(() => {
    const { companyName, firstName, lastName, email, phone, password, rePassword } = formik.values
    const errorLength = Object.keys(formik.errors).length
    if (
      (companyName.trim() && firstName.trim() && lastName.trim() && email,
      phone.trim() && password.trim() && rePassword.trim() && errorLength <= 0)
    ) {
      setIsButtonDisabled(false)
    } else {
      setIsButtonDisabled(true)
    }
  }, [formik.values, formik.errors])

  const handleKeyPress = (event) => {
    const { which, keyCode } = event
    if (keyCode === 13 || which === 13) {
      if (!isButtonDisabled) {
        formik.handleSubmit()
      }
    }
  }

  const handleInputChange = (fieldName, value) => {
    if (fieldName === 'phone') {
      const numericValue = value.replace(/\D/g, '')
      formik.setFieldValue(fieldName, `+${numericValue}`)
    } else {
      formik.setFieldValue(fieldName, value)
    }
  }
  return (
    <ThemeProvider theme={ThemeNSLLight}>
      <Box className={classes.card}>
        <Card className={classes.card_wrap}>
          {isRegisterFinish ? (
            <Box className={classes.ready}>
              <div className={classes.header_title}> Thank you!</div>
              <p className={classes.text_description}>
                We received your data and will come back as soon as possible to you.
              </p>
              <p className={classes.text_description}>
                Meanwhile please approve your account by clicking the link in the email{' '}
                <span className={classes.bolder_text}>({currentEmail})</span> we sent you (please also check your SPAM).
              </p>
            </Box>
          ) : (
            <form className={classes.form} noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
              <Box className={classes.header}>
                <div className={classes.header_title}>Please, tell us something about yourself.</div>
                {/* <div className={classes.header_subtitle}>Enter your email and password to sign in.</div> */}
              </Box>
              {formik.status && (
                <Alert className={classes.form_alert} severity="error">
                  {formik.status?.message}
                </Alert>
              )}
              <FormGroup className={classes.form_group}>
                <TextField
                  error={
                    !!formik.errors?.companyName &&
                    (!!formik.touched?.companyName || !!formik.values.companyName.length)
                  }
                  fullWidth
                  id="companyName"
                  type="text"
                  label="Company Name"
                  placeholder="Company Name"
                  value={formik.values?.companyName}
                  helperText={
                    (formik.touched?.companyName || formik.values.companyName.length) && formik.errors?.companyName
                      ? formik.errors?.companyName
                      : ''
                  }
                  onChange={(e) => handleInputChange('companyName', e.target.value)}
                  onFocus={() => formik.setFieldTouched('companyName', false)}
                  onBlur={() => formik.setFieldTouched('companyName', true)}
                  onKeyPress={handleKeyPress}
                />
              </FormGroup>
              <FormGroup className={classes.form_group}>
                <FormGroup className={classes.form_group_col}>
                  <TextField
                    error={
                      !!formik.errors?.firstName && (!!formik.touched?.firstName || !!formik.values.firstName.length)
                    }
                    fullWidth
                    id="firstName"
                    type="text"
                    label="First Name"
                    placeholder="First Name"
                    value={formik.values?.firstName}
                    helperText={
                      (formik.touched?.firstName || formik.values.firstName.length) && formik.errors?.firstName
                        ? formik.errors?.firstName
                        : ''
                    }
                    onChange={(e) => handleInputChange('firstName', e.target.value)}
                    onFocus={() => formik.setFieldTouched('firstName', false)}
                    onBlur={() => formik.setFieldTouched('firstName', true)}
                    onKeyPress={handleKeyPress}
                  />
                </FormGroup>
                <FormGroup className={classes.form_group_col}>
                  <TextField
                    error={!!formik.errors?.lastName && (!!formik.touched?.lastName || !!formik.values.lastName.length)}
                    fullWidth
                    id="lastName"
                    type="text"
                    label="Last Name"
                    placeholder="Last Name"
                    value={formik.values?.lastName}
                    helperText={
                      (formik.touched?.lastName || formik.values.lastName.length) && formik.errors?.lastName
                        ? formik.errors?.lastName
                        : ''
                    }
                    onChange={(e) => handleInputChange('lastName', e.target.value)}
                    onFocus={() => formik.setFieldTouched('lastName', false)}
                    onBlur={() => formik.setFieldTouched('lastName', true)}
                    onKeyPress={handleKeyPress}
                  />
                </FormGroup>
              </FormGroup>
              <FormGroup className={classes.form_group}>
                <FormGroup className={classes.form_group_col}>
                  <TextField
                    error={!!formik.errors?.email && (!!formik.touched?.email || !!formik.values.email.length)}
                    fullWidth
                    id="email"
                    type="email"
                    label="Your email address"
                    placeholder="Your email address"
                    value={formik.values?.email}
                    helperText={
                      (formik.touched?.email || formik.values.email.length) && formik.errors?.email
                        ? formik.errors?.email
                        : ''
                    }
                    onChange={(e) => handleInputChange('email', e.target.value)}
                    onFocus={() => formik.setFieldTouched('email', false)}
                    onBlur={() => formik.setFieldTouched('email', true)}
                    onKeyPress={handleKeyPress}
                  />
                </FormGroup>
                <FormGroup className={classes.form_group_col}>
                  <TextField
                    error={!!formik.errors?.phone && (!!formik.touched?.phone || !!formik.values.phone.length)}
                    fullWidth
                    id="phone"
                    type="text"
                    label="Your Phone Number"
                    placeholder="Your Phone Number"
                    value={formik.values?.phone}
                    helperText={
                      (formik.touched?.phone || formik.values.phone.length) && formik.errors?.phone
                        ? formik.errors?.phone
                        : ''
                    }
                    onChange={(e) => handleInputChange('phone', e.target.value)}
                    onFocus={() => formik.setFieldTouched('phone', false)}
                    onBlur={() => formik.setFieldTouched('phone', true)}
                    onKeyPress={handleKeyPress}
                  />
                </FormGroup>
              </FormGroup>
              <FormGroup className={classes.form_group}>
                <FormGroup className={classes.form_group_col}>
                  <TextField
                    error={!!formik.errors?.password && (!!formik.touched?.password || !!formik.values.password.length)}
                    fullWidth
                    id="password"
                    type="password"
                    label="Your password"
                    placeholder="Your password"
                    value={formik.values?.password}
                    helperText={
                      (formik.touched?.password || formik.values.password.length) && formik.errors?.password
                        ? formik.errors?.password
                        : ''
                    }
                    onChange={(e) => handleInputChange('password', e.target.value)}
                    onFocus={() => formik.setFieldTouched('password', false)}
                    onBlur={() => formik.setFieldTouched('password', true)}
                    onKeyPress={handleKeyPress}
                  />
                </FormGroup>
                <FormGroup className={classes.form_group_col}>
                  <TextField
                    error={
                      !!formik.errors?.rePassword && (!!formik.touched?.rePassword || !!formik.values.rePassword.length)
                    }
                    fullWidth
                    id="rePassword"
                    type="password"
                    label="Re-enter your password"
                    placeholder="Re-enter your password"
                    value={formik.values?.rePassword}
                    helperText={
                      (formik.touched?.rePassword || formik.values.rePassword.length) && formik.errors?.rePassword
                        ? formik.errors?.rePassword
                        : ''
                    }
                    onChange={(e) => handleInputChange('rePassword', e.target.value)}
                    onFocus={() => formik.setFieldTouched('rePassword', false)}
                    onBlur={() => formik.setFieldTouched('rePassword', true)}
                    onKeyPress={handleKeyPress}
                  />
                </FormGroup>
              </FormGroup>
              <FormGroup className={classes.form_group_checkbox}>
                <CheckboxRound id="confirm" onChange={(value) => handleInputChange('isConfirm', value)}>
                  Please, check here to confirm that you have read and agree to the{' '}
                  <a href="https://www.deepcliq.ai/terms/" target="_blank" rel="noreferrer">
                    Terms&nbsp;and Conditions
                  </a>{' '}
                  as well as the{' '}
                  <a href="https://www.deepcliq.ai/privacy/" target="_blank" rel="noreferrer">
                    Privacy&nbsp;Policy
                  </a>{' '}
                  of Neural Search Labs GmbH.
                </CheckboxRound>
              </FormGroup>
              <CardActions className={classes.form_footer}>
                <Box className={classes.form_footer_box}>
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    className={classes.form_btn}
                    disabled={isButtonDisabled}
                    type="submit"
                  >
                    Request Account
                  </Button>
                </Box>
              </CardActions>
            </form>
          )}
        </Card>
      </Box>
    </ThemeProvider>
  )
}
export default SignUpForm
